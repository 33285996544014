.store-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    text-align: center;
    position: relative;
    overflow: hidden; /* Empêche le défilement du contenu de ce conteneur */
  }

  .form * {
    font-family: "InterVariable" !important;
  }




  .store-container .form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

  .fade-enter {
    opacity: 0;
    transform: rotateX(90deg);
    transition: opacity 1000ms ease-in, transform 500ms ease-in;
  }

  .fade-enter-active {
    opacity: 1;
    transform: rotateX(0deg) ;
  }

  .fade-exit {
    opacity: 1;
    transform: rotateX(0deg);
  }

  .fade-exit-active {
    opacity: 0;
    transform: rotateX(90deg);
    transition: opacity 1000ms ease-in, transform 100ms cubic-bezier(.17,.67,.49,1.56);
  }

  
  .store-container .form2 .next{
    font-size: 1.5rem;
    margin-left: 10px;
    color: #7A1928;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;
}

.store-container .form2 .previous{
    font-size: 1.5rem;
    margin-right: 10px;
    color: #7A1928;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;

}
.store-container .form2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
}
.store-container .form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}
.next-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #7A1928;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}