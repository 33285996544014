/* Chatbot.css */


#background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#background p {
    font-size: 3rem;
    font-weight: lighter;
    color: #bababa;
    text-align: center;
    animation: fade 2s infinite;
}

@keyframes fade {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

button {
    padding: 10px 20px;
    font-size: 1.5rem;
    margin-top: 20px;
    cursor: pointer;
}
